import * as turf from '@turf/turf';

export const animateFrame = (map, playRoute, stopAnimate, lastPosition, steps) => {
  const route = playRoute.attributes.route;
  const name = playRoute.attributes.name;
  // A single point that animates along the route.
  // Coordinates are initially set to origin.
  const point = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          name
        },
        geometry: {
          type: 'Point',
          coordinates: route.features[0].geometry.coordinates[0]
        }
      }
    ]
  };

  // Calculate the distance in kilometers between route start/end point.
  const lineDistance = turf.lineDistance(route.features[0], 'kilometers');

  const arc = [];

  // Number of steps to use in the arc and animation, more steps means
  // a smoother arc and animation, but too many steps will result in a
  // low frame rate
  // var steps = 1000;

  // Draw an arc between the `origin` & `destination` of the two points
  for (let i = 0; i <= lineDistance; i += lineDistance / steps) {
    const segment = turf.along(route.features[0], i, 'kilometers');
    arc.push(segment.geometry.coordinates);
  }

  // Update the route with calculated arc coordinates
  route.features[0].geometry.coordinates = arc;

  // Used to increment the value of the point measurement against the route.
  let counter = 0;

  // Set the coordinates of the original point back to origin
  point.features[0].geometry.coordinates = route.features[0].geometry.coordinates[0];

  // Update the source layer
  map.getSource(playRoute.attributes.imei).setData(point);

  // Reset the counter
  counter = 0;

  // Restart the animation.
  animate(counter);

  function animate() {
    // console.log("route animate", counter);
    // Update point geometry to a new position based on counter denoting
    // the index to access the arc.
    point.features[0].geometry.coordinates = route.features[0].geometry.coordinates[counter];

    lastPosition(route.features[0].geometry.coordinates[counter], playRoute.id);

    // Calculate the bearing to ensure the icon is rotated to match the route arc
    // The bearing is calculate between the current point and the next point, except
    // at the end of the arc use the previous point and the current point
    point.features[0].properties.bearing = turf.bearing(
      turf.point(route.features[0].geometry.coordinates[counter >= steps ? counter - 1 : counter]),
      turf.point(route.features[0].geometry.coordinates[counter >= steps ? counter : counter + 1])
    );

    // Update the source with this new data.
    map.getSource(playRoute.attributes.imei).setData(point);

    // Request the next frame of animation so long the end has not been reached.
    if (counter < steps) {
      requestAnimationFrame(animate);
    }
    // stop animate and change icon
    if (counter === steps - 1) {
      stopAnimate(playRoute.id);
    }

    counter = counter + 1;
  }
};
