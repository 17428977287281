import moment from 'moment';
import React from 'react';
import mapIcon from './mapIcon';
import { isTimeDifferenceMoreThan10Minutes } from '../../../utils';
const bikeData = ({ data }) => {
  // loop thru data if and split every 4 items
  // take sensor out
  const dataFlate = { ...data.sensor, ...data, };
  dataFlate.update = dataFlate.deviceTime;
  delete dataFlate.sensor;
  delete dataFlate.createdAt;
  delete dataFlate.updatedAt;
  delete dataFlate.serverTime;
  delete dataFlate.publishedAt;
  delete dataFlate.altitude;
  delete dataFlate.battery;
  delete dataFlate.angle;
  // delete dataFlate.longitude;
  // delete dataFlate.latitude;
  delete dataFlate.deviceTime;
  delete dataFlate.picture;
  delete dataFlate.business_hours;
  delete dataFlate.organization;

  // foramting data
  dataFlate.update = moment(dataFlate.update).format('YY-MM-DD HH:mm:ss');
  dataFlate.ignition = dataFlate.ignition ? 'On' : 'Off';
  dataFlate.mileage = dataFlate.mileage + ' Km';
  console.log(dataFlate);
  if (dataFlate.speed !== 0) {
    dataFlate.status = "driving"
  } else {
    dataFlate.status = "stop"
  }
  dataFlate.speed = dataFlate.speed + ' Km/h';

  if (!isTimeDifferenceMoreThan10Minutes(new Date(dataFlate.update), new Date())) {
    dataFlate.online = "Yes"
  } else {
    dataFlate.online = "No"
  }
  dataFlate.latitude = parseFloat(dataFlate.latitude).toFixed(5);
  dataFlate.longitude = parseFloat(dataFlate.longitude).toFixed(5);


  return (
    <div className="">
      <div className="flex mb-1">
        <div className="badge badge-secondary badge-outline">Vehicle Data</div>
      </div>
      <div className="flex overflow-x-scroll h-32 no-scrollbar bg-white pt-2 rounded-lg">
        <div className="flex flex-col justify-center bg-white">
          <img src="../assets/bike.jpg" className="my-auto thumbnail" alt="bike" width={80} />
        </div>
        {getChunk(Object.keys(dataFlate)).map((each) => {
          return (
            <>
              <ul className="menu menu-compact bg-base-100 w-64 px-2 justify-start">
                {each.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className="flex justify-between py-[1px]">
                        <div className="flex space-x-2">
                          <div className="flex text-sm">
                            {mapIcon[item] && (
                              <img src={`../assets/svg/${mapIcon[item]}`} alt="icons" width={18} />
                            )}
                            {!mapIcon[item] && (
                              <img src={'../assets/svg/default-sensor.svg'} alt="icons" width={18} />
                            )}
                          </div>
                          <div className="flex text-sm justify-start">
                            <p className="text-start">{item}</p>
                          </div>
                        </div>
                        <div className="flex justify-end text-sm">
                          <span className="overflow-hidden truncate">{dataFlate[item]}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          );
        })}
      </div>
    </div>
  );
};

const getChunk = (array) => {
  const chunkSize = 5;
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    // do whatever
    result[i] = chunk;
  }
  return result;
};

export default bikeData;
