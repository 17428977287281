import { motion } from 'framer-motion';
import * as React from 'react';
import { AppContext } from '../../../AppContext';
import Loading from '../../../shared/loading';
import { MenuItem } from './device';

export const DeviceList = () => {
  const { store, actions } = React.useContext(AppContext);
  const [showDevice, setShowDevice] = React.useState([]);
  const [showStation, setShowStation] = React.useState([]);
  const checkedCallbackDevice = (item, i) => {
    const newShowDevice = [...showDevice];
    newShowDevice[i] = !newShowDevice[i];
    setShowDevice(newShowDevice);
    // hide map on marker
    // console.log(store.map);
    store.map.setLayoutProperty(item.imei, 'visibility', !newShowDevice[i] ? 'none' : 'visible');
  };

  const checkedCallbackStation = (item, i) => {
    const newShowStation = [...showStation];
    newShowStation[i] = !newShowStation[i];
    setShowStation(newShowStation);
    // hide map on marker
    // console.log(store.map);
    store.map.setLayoutProperty(item.imei, 'visibility', !newShowStation[i] ? 'none' : 'visible');
  };

  React.useEffect(() => {
    if (store.stationPositions.length !== 0 && store.devicePositions.length !== 0) {
      if (store.activeTab === 'bike') {
        actions.setSelectedDevice(store.devicePositions.data[0].attributes, store);
      } else {
        console.log('attributes', store.stationPositions.data[0].attributes);
        actions.setSelectedDevice(store.stationPositions.data[0].attributes, store);
      }
    }
  }, [store.activeTab]);

  React.useEffect(() => {
    if (store.map && store.selectedDevice) {
      store.map.flyTo({
        center: [store.selectedDevice.longitude, store.selectedDevice.latitude],
        zoom: 16.2
      });
    }
  }, [store.selectedDevice, store.map])

  console.log(store.selectedDevice);

  React.useEffect(() => {
    if (store.devicePositions.data && showDevice.length === 0) {
      const defaultShow = new Array(store.devicePositions.data.length).fill(true);
      setShowDevice([...defaultShow]);
      if (store.stationPositions.data && showStation.length === 0) {
        const defaultShow = new Array(store.stationPositions.data.length).fill(true);
        setShowStation([...defaultShow]);
      }
    }
  }, [store.devicePositions]);

  React.useEffect(() => {
    if (store.stationPositions.data && showStation.length === 0) {
      const defaultShow = new Array(store.stationPositions.data.length).fill(true);
      setShowStation([...defaultShow]);
    }
  }, [store.stationPositions]);

  // console.log(store.devicePositions);
  if (!store.devicePositions.data) {
    return <Loading />;
  }

  return (
    <div className="mt-0">
      <div className="tabs w-full">
        <div
          className={`w-1/2 tab tab-lifted ${store.activeTab === 'bike' ? 'tab-active' : ''}`}
          onClick={() => {
            actions.setActiveTab('bike', store);
          }}>
          <span className="text-lg font-bold">Bike</span>
        </div>
        <div
          className={`w-1/2 tab tab-lifted ${store.activeTab === 'station' ? 'tab-active' : ''}`}
          onClick={() => {
            actions.setActiveTab('station', store);
          }}>
          <span className="text-lg font-bold">Station</span>
        </div>
      </div>
      {store.activeTab === 'bike' && (
        <div className="overflow-y-scroll overflow-x-hidden no-scrollbar h-sidebar pl-3">
          <motion.ul
            initial={{ opacity: 0.7, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}>
            {store.searchTerm &&
              store.filterDevices.data &&
              store.filterDevices.data.map((item, index) => (
                <MenuItem
                  i={index}
                  key={index}
                  data={item.attributes}
                  show={showDevice}
                  checkedCallback={checkedCallbackDevice}
                  logo="../assets/bike.jpg"
                  type={'bike'}
                />
              ))}
            {!store.searchTerm &&
              store.devicePositions.data &&
              store.devicePositions.data.map((item, index) => (
                <MenuItem
                  i={index}
                  key={index}
                  data={item.attributes}
                  show={showDevice}
                  checkedCallback={checkedCallbackDevice}
                  logo="../assets/bike.jpg"
                  type={'bike'}
                />
              ))}
          </motion.ul>
        </div>
      )}
      {store.activeTab === 'station' && (
        <div className="overflow-scroll overflow-x-hidden no-scrollbar h-sidebar pl-3">
          <motion.ul
            initial={{ opacity: 0.7, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}>
            {store.stationPositions.data &&
              store.stationPositions.data.map((item, index) => (
                <MenuItem
                  i={index}
                  key={index}
                  data={item.attributes}
                  show={showStation}
                  checkedCallback={checkedCallbackStation}
                  logo="../assets/station.png"
                  type={'station'}
                />
              ))}
          </motion.ul>
        </div>
      )}
    </div>
  );
};
