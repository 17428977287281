import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import BatteryData from './batteryData';
import BatteryStation from './batteryStation';
import BikeData from './bikeData';
import Dashboard from './dashboardData';
import StationData from './stationData';

const Detail = () => {
  const { store } = useContext(AppContext);

  return (
    <div className="flex h-auto w-full space-x-1 overflow-scroll no-scrollbar mt-2">
      {store.selectedDevice && (
        <>
          <div className="flex w-3/7 rounded-md">
            {store.activeTab === 'bike' && <BikeData data={store.selectedDevice} />}
            {store.activeTab === 'station' && <StationData data={store.selectedDevice} />}
          </div>
          <div className="flex w-auto rounded-lg">
            {store.activeTab === 'bike' && <BatteryData data={store.selectedDevice} />}
            {store.activeTab === 'station' && <BatteryStation data={store.selectedDevice} />}
          </div>
        </>
      )}

      {store.activeTab === 'bike' && (
        <div className="flex w-full rounded-md borde h-full">
          <Dashboard data={store.devicePositions} />
        </div>
      )}
    </div>
  );
};

export default Detail;
