import { useState } from 'react';
import { Modal } from 'antd';
import { sendCommand } from '../../../utils';

function Actions() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTurnOff, setIsTurnOff] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    sendCommand(isTurnOff);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="m-3 flex">
        <button
          className="btn btn-accent mr-3"
          onClick={() => {
            setIsTurnOff(true);
            setIsModalOpen(true);
          }}>
          Unlock (00)
        </button>
        <button
          className="btn btn-accent"
          onClick={() => {
            setIsTurnOff(false);
            setIsModalOpen(true);
          }}>
          Lock (01)
        </button>
      </div>

      <Modal title="Confirm Operation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>{isTurnOff ? 'Unlocked Engine' : 'Locked Engine'}</p>
      </Modal>
    </>
  );
}

export default Actions;
