import { getColor } from '../../../utils';

const batteryStation = ({ data }) => {
  // loop thru data if and split every 4 items
  if (!data.battery) {
    return (
      <div className="flex w-48 pt-3 h-auto bg-white border rounded-md justify-center items-center">
        <p className="text-md">No battery data found.</p>
      </div>
    );
  }
  return (
    <div className="space-y-1">
      <div className="flex mb-1">
        <div className="badge badge-secondary badge-outline">Battery Station Data</div>
      </div>
      <div className="flex overflow-x-scroll no-scrollbar">
        <div className="flex w-56 h-32 bg-white justify-between">
          <div className="flex justify-center w-20 pt-1 h-auto">
            <img
              src="../assets/station.png"
              className="my-auto"
              alt="battery"
              width={120}
              height={200}
            />
          </div>
          <div className="grid grid-cols-2 gap-1 m-2">
            {data.battery.map((each, index) => (
              <div
                key={index}
                className={`flex border border-indigo-500 ${getColor(
                  each.percent
                )} w-14 h-6 rounded-md justify-center text-sm`}>
                {each.status ? each.percent + ' %' : 'Free'}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default batteryStation;
