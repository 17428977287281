import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { animateFrame } from '../../../utils/animateFrame';
import './map.css';

// setup simulation marker
const el = document.createElement('div');
el.className = 'car_marker';
const marker = new maplibregl.Marker(el);
marker.setLngLat([2.335, 48.858]);

const colors = {
  stop: '#ff0000',
  idle: '#ffe511',
  driving: '#008000',
  online: '#ff0000',
  offline: '#808080'
};

export default function Map() {
  const { store, actions } = useContext(AppContext);
  const [map, setMap] = useState();
  const [counter, setCounter] = React.useState(0);
  const [prevPosition, setPrevPosition] = useState([]);

  const mapContainerRef = useRef();

  const addBikePosition = () => {
    try {
      setCounter(1);
      setPrevPosition([...store.devicePositions.data]);
      if (map) {
        const images = [{ imageUrl: '../assets/navigation_stop.png', id: 'driving' }];
        images.forEach((img) => {
          map.loadImage(img.imageUrl, function (error, res) {
            map.addImage(img.id, res, {
              sdf: 'true'
            });
            console.log(error);
          });
        });
        store.devicePositions.data.map((each, index) => {
          map.addSource(each.attributes.imei, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {
                    bearing: each.attributes.angle,
                    name: each.attributes.name
                  },
                  geometry: {
                    type: 'Point',
                    coordinates: [each.attributes.longitude, each.attributes.latitude]
                  }
                }
              ]
            }
          });
          map.addLayer({
            id: each.attributes.imei,
            source: each.attributes.imei,
            type: 'symbol',
            layout: {
              'icon-image': 'driving',
              'icon-rotate': ['get', 'bearing'],
              'icon-rotation-alignment': 'map',
              // "icon-ignore-placement": true,,
              'icon-allow-overlap': true
            },
            paint: {
              'icon-color': colors.driving,
              'icon-halo-color': '#fff',
              'icon-halo-width': 2
            }
          });
          // Create a popup, but don't add it to the map yet.
          const popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false
          });

          map.on('mouseenter', each.attributes.imei, function (e) {
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const name = e.features[0].properties.name;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(e.features[0].geometry.coordinates).setHTML(name).addTo(map);
          });

          map.on('mouseleave', each.attributes.imei, function () {
            map.getCanvas().style.cursor = '';
            popup.remove();
          });

          return null;
        });

        // zoom to first bike
        map.flyTo({
          center: [
            store.devicePositions.data[0].attributes.longitude,
            store.devicePositions.data[0].attributes.latitude
          ],
          zoom: 14,
          essential: true
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addStationPosition = () => {
    try {
      if (map) {
        const images = [{ imageUrl: '../assets/station_map.png', id: 'station' }];
        images.forEach((img) => {
          return map.loadImage(img.imageUrl, function (error, res) {
            map.addImage(img.id, res);
          });
        });
        store.stationPositions.data.map((each, index) => {
          map.addSource(each.attributes.imei, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: { name: each.attributes.name },
                  geometry: {
                    type: 'Point',
                    coordinates: [each.attributes.longitude, each.attributes.latitude]
                  }
                }
              ]
            }
          });
          map.addLayer({
            id: each.attributes.imei,
            source: each.attributes.imei,
            type: 'symbol',
            layout: {
              'icon-image': 'station',
              'icon-rotation-alignment': 'map',
              'icon-allow-overlap': true
            }
          });
          // Create a popup, but don't add it to the map yet.
          const popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false
          });

          map.on('mouseenter', each.attributes.imei, function (e) {
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const name = e.features[0].properties.name;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(e.features[0].geometry.coordinates).setHTML(name).addTo(map);
          });

          map.on('mouseleave', each.attributes.imei, function () {
            map.getCanvas().style.cursor = '';
            popup.remove();
          });

          return null;
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=vUQYfcExRkQnBn47jIex',
      center: [100.5642042, 13.8818957], // NT Location
      zoom: 8,
      trackResize: true
      // pitch: 45,
    });

    map.addControl(new maplibregl.NavigationControl(), 'top-left');
    // Add geolocate control to the map.
    map.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }),
      'top-left'
    );
    // set map to local state
    map.on('load', function () {
      setMap(map);
      actions.setMap(map, store);
    });
    return () => {
      map.remove();
    };
  }, []);


  React.useEffect(() => {
    if (map && store.stationPositions.data) {
      addStationPosition(); // this is for customer
    }
  }, [store.stationPositions, map]);
  React.useEffect(() => {
    if (map && store.devicePositions.data && counter === 0) {
      addBikePosition(); // this is for customer
    }
  }, [store.devicePositions, map]);

  React.useEffect(() => {
    // do animation
    if (counter > 0 && store.devicePositions.data) {
      store.devicePositions.data.map((each, index) => {
        // change icon color
        // update icons
        map.setPaintProperty(each.attributes.imei, 'icon-color', colors[each.attributes.status]);

        // if different point
        if (
          prevPosition[index].attributes.longitude !== each.attributes.longitude &&
          prevPosition[index].attributes.latitude !== each.attributes.latitude
        ) {
          animateFrame(
            map,
            {
              ...each,
              attributes: {
                ...each.attributes,
                route: {
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: [
                          [
                            prevPosition[index].attributes.longitude,
                            prevPosition[index].attributes.latitude
                          ], // previous position
                          [each.attributes.longitude, each.attributes.latitude] // current position
                        ]
                      },
                      properties: {}
                    }
                  ]
                }
              }
            },
            () => { }, // end animation callback
            () => { }, // last postion callback
            300
          );
        }

        return null;
      });

      // set new previous
      setPrevPosition([...store.devicePositions.data]);
    }
  }, [store.devicePositions, counter]);

  return (
    <div className="map-wrap">
      <div ref={mapContainerRef} className="map" />
    </div>
  );
}
