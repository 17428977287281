import Layout from './layout';
import Details from './detail';
import Actions from './action';

function DeatilsData() {
  return (
    <Layout>
      <Details />
      <Actions />
    </Layout>
  );
}

export default DeatilsData;
