import {
  Refine,
  Authenticated,
} from "@refinedev/core";
import { Toaster } from 'react-hot-toast';
import {
  useNotificationProvider,
  ErrorComponent,
  RefineThemes,
} from "@refinedev/antd";
import { DataProvider } from "@refinedev/strapi-v4";
import routerProvider, {
  NavigateToResource,
  CatchAllNavigate,
  UnsavedChangesNotifier,
  DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { Empty } from 'antd';
import axios from "axios";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import "@refinedev/antd/dist/reset.css";

import { UserList } from "./pages/users";
import { API_URL } from "./utils/constants";
import { ConfigProvider, App as AntdApp } from "antd";
import Navbar from "./layout/navbar";
import MapLayout from "./pages/maps/MapLayout";
import { Layout } from "./layout";
import DashboardPage from "./pages/dashboard";
import { CustomSider } from "./layout/sider";
import SimulationScreen from "./pages/simulation";
import NotificationAdmin from "./pages/notificationAdmin";
import { authProvider } from "./authProvider";
import { LoginPage } from "./pages/login/index";
import { DeviceList } from "./pages/devices/list";
import { AdminPanelSettingsOutlined, AnimationOutlined, BikeScooterOutlined, DashboardOutlined, DeviceHub, EvStationOutlined, Map, MapOutlined, NotificationAdd, NotificationsOutlined, ShoppingCartCheckoutOutlined, VerifiedUserOutlined } from "@mui/icons-material";
import { StationList } from "./pages/stations/list";
import { ContextWrapper } from "./AppContext";

const App = () => {
  const axiosInstance = axios.create();
  const dataProvider = DataProvider(API_URL, axiosInstance);
  const tenant = "1";
  return (
    <BrowserRouter>
      <ContextWrapper>
        <ConfigProvider theme={RefineThemes.Blue}>
          <Toaster position="bottom-right" reverseOrder={false} />
          <AntdApp >
            <Refine
              authProvider={authProvider}
              dataProvider={dataProvider}
              routerProvider={routerProvider}
              resources={[
                {
                  name: "dashboard",
                  list: "/:tenant/dashboard",
                  meta: {
                    label: "Dashboard",
                    icon: <DashboardOutlined />,
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "map",
                  list: "/:tenant/map",
                  meta: {
                    label: "Map",
                    icon: <MapOutlined />,
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "Devices",
                  meta: {
                    label: "Onboard Dev.",
                    icon: <DeviceHub />
                  },
                },
                {
                  name: "devices",
                  list: "/:tenant/bikes",
                  meta: {
                    label: "Bikes",
                    icon: <BikeScooterOutlined />,
                    parent: "Devices",
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "station-positions",
                  list: "/:tenant/stations",
                  meta: {
                    label: "Stations",
                    icon: <EvStationOutlined />,
                    parent: "Devices",
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "inventory",
                  meta: {
                    label: "Devices Inv.",
                    icon: <ShoppingCartCheckoutOutlined />
                  },
                },
                {
                  name: "devices",
                  list: "/:tenant/bikes",
                  meta: {
                    label: "Bikes",
                    icon: <BikeScooterOutlined />,
                    parent: "inventory",
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "station-positions",
                  list: "/:tenant/stations",
                  meta: {
                    label: "Stations",
                    icon: <EvStationOutlined />,
                    parent: "inventory",
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "Administrators",
                  meta: {
                    label: "Administrators",
                    icon: <AdminPanelSettingsOutlined />
                  },
                },
                {
                  name: "simulation",
                  list: "/:tenant/simulation",
                  meta: {
                    label: "Simulation",
                    icon: <AnimationOutlined />,
                    parent: "Administrators",
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "notifiaction",
                  list: "/:tenant/notification",
                  meta: {
                    label: "Notification",
                    icon: <NotificationsOutlined />,
                    parent: "Administrators",
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "bike-users",
                  list: "/:tenant/users",
                  meta: {
                    label: "User",
                    icon: <VerifiedUserOutlined />,
                    parent: "Administrators",
                    meta: {
                      tenant,
                    },
                  },
                },
                {
                  name: "Carbons",
                  meta: {
                    label: "Carbon Credit",
                    icon: <AdminPanelSettingsOutlined />
                  },
                },
                {
                  name: "carbon-credit",
                  list: "/:tenant/carbons",
                  meta: {
                    label: "Carbons",
                    icon: <VerifiedUserOutlined />,
                    parent: "Carbons",
                    meta: {
                      tenant,
                    },
                  },
                },
              ]}
              notificationProvider={useNotificationProvider}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated
                      key="authenticated-routes"
                      fallback={
                        <CatchAllNavigate to="/login" />
                      }
                    >
                      <Layout Header={Navbar} Sider={CustomSider} >
                        <Outlet />
                      </Layout>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={
                      <NavigateToResource resource="users" />
                    }
                  />

                  <Route path="/:tenant">
                    <Route path="users">
                      <Route index element={<UserList />} />
                    </Route>
                    <Route path="bikes">
                      <Route index element={<DeviceList />} />
                    </Route>
                    <Route path="stations">
                      <Route index element={<StationList />} />
                    </Route>
                    <Route path='dashboard'>
                      <Route index element={<DashboardPage />} />
                    </Route>
                    <Route path='simulation'>
                      <Route index element={<SimulationScreen />} />
                    </Route>
                    <Route path='notification'>
                      <Route index element={<NotificationAdmin />} />
                    </Route>
                    <Route path="map">
                      <Route index element={
                        <MapLayout />} />
                    </Route>
                    <Route path="carbons">
                      <Route index element={
                        <div className="flex justify-center h-map items-center" >
                          <Empty />
                        </div>} />
                    </Route>
                  </Route>
                </Route>

                <Route
                  element={
                    <Authenticated
                      key="auth-pages"
                      fallback={<Outlet />}
                    >
                      <NavigateToResource resource="dashboard" />
                    </Authenticated>
                  }
                >

                  <Route path='/login' element={<LoginPage />} />
                </Route>

                <Route
                  element={
                    <Authenticated key="catch-all">
                      <Layout Header={Navbar}>
                        <Outlet />
                      </Layout>
                    </Authenticated>
                  }
                >
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>
              <UnsavedChangesNotifier />
              <DocumentTitleHandler />
            </Refine>
          </AntdApp>
        </ConfigProvider >
      </ContextWrapper>
    </BrowserRouter >
  );
};

export default App;