import { useGetIdentity } from '@refinedev/core';
import { REDASH } from '../../utils/constants';

function DashboardPage() {
  const { data } = useGetIdentity();
  return (
    <iframe
      title="dashboard"
      className="h-map w-full"
      src={
        data?.role === 'admin'
          ? `${REDASH}/public/dashboards/e5YMA2puKv7untTRV0kIXxGvCiuOUCU5EhDSAcV2?org_slug=default`
          : `${REDASH}/public/dashboards/MWocGkVQI7lp2ojWIS7pb2d7qcOxARpxYlA6N5OQ?org_slug=default`
      }
    />
  );
}

export default DashboardPage;
