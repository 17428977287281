import { useLogin } from '@refinedev/core';
import {
  Layout as AntdLayout,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography
} from 'antd';
import React from 'react';
import './styles.css';

const { Text, Title } = Typography;

export const LoginPage = () => {
  const [form] = Form.useForm();

  const { mutate: login } = useLogin();

  const CardTitle = (
    <Title level={3} className="title" style={{ marginTop: 20, color: 'orange' }}>
      Sign in your account
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
          margin: "auto",
          width: "500px"
        }}>
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img src="./logo.png" alt="Moeve Logo" className='w-48' />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                  email: '',
                  password: ''
                }}>
                <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                  <Input size="large" placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}>
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <div style={{ marginBottom: '12px' }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: '12px'
                      }}>
                      Remember me
                    </Checkbox>
                  </Form.Item>
                </div>
                <Button type="primary" className='bg-blue-500' size="large" htmlType="submit" block>
                  Sign in
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
