import React from 'react';
import toast from 'react-hot-toast';
import { sendNotification } from '../../utils';

function NotificationDialog({ data }) {
  const [title, setTitle] = React.useState('');
  const [body, setBody] = React.useState('');

  const doSendNotification = async () => {
    if (!title || !body) {
      return alert('Please file in title/body');
    }
    console.log('send');
    console.log(data);
    const response = await sendNotification(
      data.row.original.fcm,
      title,
      body,
      data.row.original.id
    );
    console.log(response);
    if (response === 'ok') {
      return toast.success('Notification sent successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      });
    }
  };
  return (
    <>
      <label htmlFor="my-modal-6" className="btn btn-xs btn-primary">
        Send Notifcation
      </label>
      <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box flex flex-col space-y-2">
          <h3 className="font-bold text-lg">Please fill information to send!</h3>
          <input
            type="text"
            placeholder="Title"
            className="input input-bordered w-full max-w-xs"
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            className="textarea w-full input-bordered "
            placeholder="Body"
            onChange={(e) => setBody(e.target.value)}></textarea>
          <div className="modal-action">
            <label htmlFor="my-modal-6" className="btn" onClick={doSendNotification}>
              Send
            </label>
          </div>
        </div>
      </div>{' '}
    </>
  );
}

export default NotificationDialog;
