function Loading() {
  return (
    <div className=" flex flex-col w-full p-2">
      <progress className="progress progress-success w-full my-2"></progress>
      {[1, 2, 3, 4, 5, 6, 7].map((each, index) => (
        <div
          key={index}
          className="relative px-2 py-1 w-full bg-white rounded-lg overflow-hidden shadow-lg border-2 mt-2">
          <div className="animate-pulse flex space-x-4 items-center">
            <div className=" bg-gray-200 rounded w-8 h-8 "></div>
            <div className="flex-1 space-y-1 py-1">
              <div className="h-2 bg-gray-200 rounded w-3/4"></div>
              <div className="h-2 bg-gray-200 rounded"></div>
              <div className="h-2 bg-gray-200 rounded w-3/4"></div>
              <div className="h-2 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Loading;
