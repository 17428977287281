import KPICard from './kpiCard';
import Layout from './layout';
import DriverRanking from './rankDriver';

function OverviewBoard() {
  return (
    <Layout>
      <DriverRanking />
      <KPICard />
    </Layout>
  );
}

export default OverviewBoard;
