import { Badge } from '@tremor/react';
import _ from 'lodash';

function Dashboard({ data }) {
  const countDriving = _.filter(data.data, (item) => item.attributes.status === 'driving');
  const countIdle = _.filter(data.data, (item) => item.attributes.status === 'idle');
  const countStop = _.filter(
    data.data,
    (item) => item.attributes.status === 'stop' || item.attributes.status === 'online'
  );

  if (!data.data) {
    return <loading />;
  }
  return (
    <div className="flex-col space-y-1">
      <div className="flex mb-1">
        <div className="badge badge-primary badge-outline">Overview Data</div>
      </div>
      <div className="stats w-full h-32 flex">
        <div className="stat place-items-center p-2">
          <div className="stat-title font-bold">Total</div>
          <div className="stat-value">{data.data.length}</div>
          <Badge text="100%" color="blue" size="sm" icon={undefined} tooltip="" marginTop="mt-0" />
        </div>
        <div className="stat place-items-center p-2">
          <div className="stat-title font-bold">Stop</div>
          <div className="stat-value text-red-700">{countStop.length}</div>
          <Badge
            text={`${parseFloat((countStop.length / data.data.length) * 100).toFixed(2)} %`}
            color="blue"
            size="sm"
            icon={undefined}
            tooltip=""
            marginTop="mt-0"
          />
        </div>
        <div className="stat place-items-center p-2">
          <div className="stat-title font-bold">Idle</div>
          <div className="stat-value text-yellow-500">{countIdle.length}</div>
          <Badge
            text={`${parseFloat((countIdle.length / data.data.length) * 100).toFixed(2)} %`}
            color="blue"
            size="sm"
            icon={undefined}
            tooltip=""
            marginTop="mt-0"
          />
        </div>
        <div className="stat place-items-center p-2">
          <div className="stat-title font-bold">Driving</div>
          <div className="stat-value text-green-500">{countDriving.length}</div>
          <Badge
            text={`${parseFloat((countDriving.length / data.data.length) * 100).toFixed(2)} %`}
            color="blue"
            size="sm"
            icon={undefined}
            tooltip=""
            marginTop="mt-0"
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
