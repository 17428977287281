import { Icon } from '@tremor/react';
import moment from 'moment';
import * as React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  MdSignalWifiStatusbar0Bar,
  MdSignalWifiStatusbar1Bar,
  MdSignalWifiStatusbar2Bar,
  MdSignalWifiStatusbar3Bar
} from 'react-icons/md';
import { AppContext } from '../../../AppContext';
import { isTimeDifferenceMoreThan10Minutes } from "../../../utils"

const colors = {
  // stop: 'bg-[#ff0000]',
  idle: 'bg-[#ffe511]',
  driving: 'bg-[#008000]',
  online: 'bg-[#ff0000]',
  offline: 'bg-[#808080]'
};

const getIcon = (signal) => {
  // eslint-disable-next-line default-case
  switch (signal) {
    case 0:
      return <MdSignalWifiStatusbar1Bar className="text-gray-500 w-5 h-5" />;
    case 1:
      return <MdSignalWifiStatusbar1Bar className="text-green-500 w-5 h-5" />;
    case 2:
      return <MdSignalWifiStatusbar2Bar className="text-green-500 w-5 h-5" />;
    case 3:
      return <MdSignalWifiStatusbar3Bar className="text-green-500 w-5 h-5" />;
    case 4:
      return <MdSignalWifiStatusbar3Bar className="text-green-500 w-5 h-5" />;
  }
};

export const MenuItem = ({ i, data, checkedCallback, show, logo, type }) => {
  const { store, actions } = React.useContext(AppContext);

  if (!isTimeDifferenceMoreThan10Minutes(new Date(data.deviceTime), new Date())) {
    data.online = false
  } else {
    data.offline = true
  }

  return (
    <li>
      <div className="flex justify-between">
        <div className="flex justify-start items-center space-x-1">
          {/* check to follow */}
          <div className="form-control w-6 h-6">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                checked={show[i]}
                onChange={() => checkedCallback(data, i)}
                className="checkbox checkbox-primary checkbox-sm"
              />
            </label>
          </div>
          <button
            onClick={() => {
              actions.setSelectedDevice(data, store);
              store.map.flyTo({
                center: [data.longitude, data.latitude],
                zoom: 16.2
              });
            }}
            className="flex space-x-2 items-center">
            {/* icons */}
            <div className="flex items-start mx-1">
              <img
                className="text-green-500"
                src={logo}
                width={type === 'bike' ? '50px' : '30px'}
                height="100%" alt="bike" />
              {store.activeTab === 'bike' && (
                <div className={`flex -ml-1 w-2 h-2 ${colors[data.status]} rounded-full`}></div>
              )}
            </div>
            {/* object name and last seen */}
            <div className="flex-col justify-start space-y-0">
              <p className="flex text-[16px] font-semibold">{data.name}</p>
              <p className="flex text-[14px]">
                {store.activeTab === 'bike' && moment(data.deviceTime).format('YYYY-MM-DD HH:mm')}
                {store.activeTab === 'station' && moment().format('YYYY-MM-DD HH:mm')}
              </p>
            </div>
            <div className="flex flex-col">
              <span className="text-[16px]">{data.speed} </span>
              <span className="text-[16px]">km/h</span>
            </div>
          </button>
        </div>

        {/* action */}
        <div className="flex justify-end items-center mr-3">
          {!data.offline && <div>{getIcon(Math.ceil(data.sensor.satellite / 3))}</div>}
          {data.offline && <div>{getIcon(0)}</div>}
        </div>
      </div>
      <hr />
    </li>
  );
};
