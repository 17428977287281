import { Tab, TabList } from '@tremor/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { BsArrowDownSquareFill, BsArrowUpSquareFill, BsClipboardData } from 'react-icons/bs';
import { CiSettings } from "react-icons/ci"

export default function Device({ children }) {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(1);
  const controls = useAnimation();

  const showMore = () => {
    controls.start({
      height: '215px',
      transition: { duration: 0.001 }
    });

    setActive(true);
  };

  const showLess = () => {
    controls.start({
      height: '0px',
      transition: { duration: 0.001 }
    });

    setActive(false);
  };

  useEffect(() => {
    showMore();
  }, []);

  return (
    <motion.div
      animate={controls}
      className="animate duration-300 absolutez flex flex-col group bg-gray-100">
      {active && (
        <BsArrowDownSquareFill
          onClick={showLess}
          className="absolute text-2xl text-gray-500 z-10 cursor-pointer right-16 -top-6 group-hover:block "
        />
      )}
      {!active && (
        <BsArrowUpSquareFill
          onClick={showMore}
          className="absolute text-2xl text-gray-500 z-10 cursor-pointer right-16 -top-5"
        />
      )}

      <div className="flex ml-2 w-full">
        {active && (
          <div className="w-full">
            <>
              <TabList
                defaultValue={value}
                handleSelect={(value) => setValue(value)}
                marginTop="mt-2">
                <Tab value={1} text="Data" icon={BsClipboardData} />
                <Tab value={2} text="Action" icon={CiSettings} />
                {/* <Tab value={3} text="Data" icon={CiTempHigh} /> */}
              </TabList>
            </>
            {value === 1 && children[0]}
            {value === 2 && children[1]}
          </div>
        )}
      </div>
    </motion.div>
  );
}
