import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import React, { useEffect, useRef } from 'react';
import { animate } from '../../utils/animate';
import './map.css';

// setup simulation marker
const el = document.createElement('div');
el.className = 'car_marker';

export default function Map({
  data,
  zoomTo,
  playRoute,
  handleStopAnimate,
  lastPosition,
  dataToSubmit
}) {
  const mapContainerRef = useRef();
  const [map, setMap] = React.useState();
  const renderRoutes = () => {
    try {
      if (map) {
        //   { imageUrl: "../assets/navigation.png", id: "driving" },
        //   { imageUrl: "../assets/navigation_idle.png", id: "idle" },

        const images = [{ imageUrl: '../assets/navigation_stop.png', id: 'driving' }];

        images.forEach((img) => {
          map.loadImage(img.imageUrl, function (error, res) {
            map.addImage(img.id, res, {
              sdf: 'true'
            });
            console.log(error);
          });
        });
        data.map((each, index) => {
          map.addSource(each.attributes.imei + 'route', {
            type: 'geojson',
            data: each.attributes.route
          });

          map.addSource(each.attributes.imei + 'point', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'Point',
                    coordinates: each.attributes.route.features[0].geometry.coordinates[0]
                  }
                }
              ]
            }
          });
          map.addLayer({
            id: each.attributes.imei + 'route',
            source: each.attributes.imei + 'route',
            type: 'line',
            layout: {
              'line-cap': 'round',
              'line-join': 'round'
            },
            paint: {
              'line-color': '#1a34ff',
              'line-width': 5,
              'line-opacity': 0.8
            }
          });

          map.addLayer({
            id: each.attributes.imei + 'point',
            source: each.attributes.imei + 'point',
            type: 'symbol',
            layout: {
              'icon-image': 'driving',
              'icon-rotate': ['get', 'bearing'],
              'icon-rotation-alignment': 'map',
              'icon-ignore-placement': true
            },
            paint: {
              'icon-color': dataToSubmit[index] ? dataToSubmit[index].color : '#1a34ff',
              'icon-halo-color': '#fff',
              'icon-halo-width': 2
            }
          });
          return null;
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleZoomTo = async (zoomTo) => {
    // Geographic coordinates of the LineString
    const coordinates = zoomTo.attributes.route.features[0].geometry.coordinates;
    const bounds = coordinates.reduce(function (bounds, coord) {
      return bounds.extend(coord);
    }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
    // console.log(coordinates, bounds);
    map.fitBounds(bounds, {
      padding: 20
    });
  };

  const handlePlayRoute = async (playRoute) => {
    await handleZoomTo(playRoute);
    animate(map, playRoute, handleStopAnimate, lastPosition, 21600); // 21600 around 360s from 5KM
  };

  const updateRouteDisplay = () => {
    dataToSubmit.map((each) => {
      try {
        return map.setLayoutProperty(
          each.imei + 'route',
          'visibility',
          each.hideRoute ? 'none' : 'visible'
        );
      } catch (err) {
        return console.log(err);
      }
    });
  };

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=vUQYfcExRkQnBn47jIex',
      center: [100.0, 13.0], // Centrage carte
      zoom: 11.7,
      trackResize: true
    });

    map.addControl(new maplibregl.NavigationControl(), 'top-left');
    // Add geolocate control to the map.
    map.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }),
      'top-left'
    );

    // add layer
    map.on('load', function () {
      // add maker
      setMap(map);
    });

    return () => {
      map.remove();
    };
  }, []);

  React.useEffect(() => {
    if (zoomTo) handleZoomTo(zoomTo);
  }, [zoomTo, handleZoomTo]);

  React.useEffect(() => {
    if (playRoute) handlePlayRoute(playRoute);
  }, [playRoute, handlePlayRoute]);

  React.useEffect(() => {
    if (map) {
      renderRoutes();
    }
    // zoom to first route
    if (data && data.length !== 0) {
      handleZoomTo(data[0]);
    }
  }, [data, map, handleZoomTo, renderRoutes]);

  React.useEffect(() => {
    // update icons
    if (map) {
      dataToSubmit.map((each) => {
        return map.setPaintProperty(each.imei + 'point', 'icon-color', each.color);
      });
    }
  }, [dataToSubmit, map]);

  React.useEffect(() => {
    // update show or hide route layout
    if (map) {
      updateRouteDisplay();
    }
  }, [dataToSubmit, map, updateRouteDisplay]);

  return (
    <div className="map-wrap">
      <div ref={mapContainerRef} className="map" />
    </div>
  );
}
