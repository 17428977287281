import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import _ from 'lodash';
import * as React from 'react';
import toast from 'react-hot-toast';
import { getUsers, sendNotification } from '../../utils';
import NotificationDialog from './dialog';

const columnHelper = createColumnHelper();
const fields = ['name', 'surename', 'nationalID', 'employeeID', 'birthday', 'email', 'position'];

function App() {
  const [data, setData] = React.useState([]);

  const columns = [
    ..._.map(fields, (each) => {
      return columnHelper.accessor(each, {
        cell: (info) => {
          return info.getValue();
        },
        footer: (info) => info.column.id
      });
    }),
    columnHelper.accessor('', {
      header: 'Send Notification',
      cell: (info) => <NotificationDialog data={info} />,
      footer: 'Send Notification'
    }),
    columnHelper.accessor('', {
      header: 'Send Logout Command',
      cell: (info) => (
        <button className="btn btn-xs" onClick={() => handleLogout(info)}>
          Logout
        </button>
      ),
      footer: 'Send Logourt Command'
    })
  ];

  const handleLogout = async (info) => {
    const response = await sendNotification(
      info.row.original.fcm,
      'logout',
      'Another device have login this account',
      info.row.original.id
    );
    if (response === 'ok') {
      return toast.success('Notification sent successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      });
    }
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });
  const fetchUsers = async () => {
    const users = await getUsers();
    console.log(users);
    // merge data
    const formattedData = _.map(users.data, (each) => ({ id: each.id, ...each.attributes }));
    setData(formattedData);
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  console.log(data);
  if (data.length === 0) {
    return 'loading';
  }

  return (
    <div className="overflow-x-auto w-full p-3">
      <table className="table w-full table-compact text-2xl">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function NotificationAdmin() {
  return <App />;
}

export default NotificationAdmin;
