import React from 'react';
import toast from 'react-hot-toast';
import { URL } from './constants';
// var serverUrl = "http://localhost:1337";
export const serverUrl = URL;
export const login = async (loginData) => {
  const login = await fetch(`${serverUrl}/api/auth/local`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(loginData)
  });
  return login;
};

export const getSimulations = async () => {
  const simulations = await fetch(`${serverUrl}/api/simulations?populate=*&sort=id`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return simulations.json();
};
export const getDevicePositions = async () => {
  const params = {
    'fields[0]': 'name',
    'fields[1]': 'imei',
    'fields[2]': 'speed',
    'fields[3]': 'angle',
    'fields[4]': 'altitude',
    'fields[5]': 'latitude',
    'fields[6]': 'longitude',
    'fields[7]': 'serverTime',
    'fields[8]': 'deviceTime',
    'fields[9]': 'sensor',
    'fields[10]': 'battery',
    'fields[11]': 'status',
    'fields[12]': 'mileage'
  };
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');

  const devicePositions = await fetch(`${serverUrl}/api/positions?sort=id:desc&${queryString}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return devicePositions.json();
};

export const getStationPositions = async () => {
  const params = {
    'fields[0]': 'name',
    'fields[1]': 'imei',
    'fields[2]': 'speed',
    'fields[3]': 'angle',
    'fields[4]': 'altitude',
    'fields[5]': 'latitude',
    'fields[6]': 'longitude',
    'fields[7]': 'serverTime',
    'fields[8]': 'deviceTime',
    'fields[9]': 'sensor',
    'fields[10]': 'battery',
    'fields[11]': 'status'
  };
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');

  const stationPositions = await fetch(
    `${serverUrl}/api/station-positions?sort=id&${queryString}&populate=*`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );
  return stationPositions.json();
};

export const getProfiles = async () => {
  const userProfile = await fetch(`${serverUrl}/api/bike-users?sort=id&populate=*`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return userProfile.json();
};

export const runSimulation = async (each) => {
  const stationPositions = await fetch(`${serverUrl}/api/utils/simulation?id=${each.id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return stationPositions.json();
};

export const stopSimulation = async () => {
  const stationPositions = await fetch(`${serverUrl}/api/utils/stopSimulation`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return stationPositions.json();
};

export const sendData = async (data) => {
  const positions = await fetch(`${serverUrl}/api/positions/${data.deviceID}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data })
  });
  return positions.json();
};

export const getUsers = async () => {
  const users = await fetch(`${serverUrl}/api/nt-users`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return users.json();
};

export const sendNotification = async (fcm, title, body, userID) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  const imageLink =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaZ3QDmXp87ws24-OO9gqXsCyTj1XPmQv9kA&usqp=CAU';
  return fetch(
    `${serverUrl}/api/utils/sendNotification?fcm=${fcm}&title=${title}&body=${body}&userID=${userID}&image=${imageLink}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => 'ok')
    .catch((error) => {
      console.log('error', error);
      return 'error';
    });
};

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export function useInterval(callback, delay) {
  const intervalRef = React.useRef();
  const callbackRef = React.useRef(callback);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setInterval ticks again, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // interval will be reset.

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval:

  React.useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

      // Clear interval if the components is unmounted or the delay changes:
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);

  // Returns a ref to the interval ID in case you want to clear it manually:
  return intervalRef;
}

export const getColor = (percent) => {
  if (percent === 0) {
    return 'bg-white';
  }
  if (percent <= 20) {
    return 'bg-red-500 text-white';
  }
  if (percent <= 50) {
    return 'bg-yellow-500';
  }
  if (percent <= 75) {
    return 'bg-green-300 text-white';
  }
  if (percent <= 100) {
    return 'bg-green-600 text-white';
  }
};

export const isTimeDifferenceMoreThan10Minutes = (date1, date2) => {
  return Math.abs(date1 - date2) > 10 * 60 * 1000;
}

export const sendCommand = (isTurnOff) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "command": isTurnOff ? "40400012142170323182FF41140073630D0A" : "40400012142170323182FF41140163420D0A"
  });

  console.log(raw);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  fetch("http://35.209.61.156:4001/send-command", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));

  return toast.success('Command sent successfully', {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });
}