import {
  List,
  useDrawerForm,
  useTable,
  EditButton,
  Edit,
  Create,
  RefreshButton
} from '@refinedev/antd';
import { mediaUploadMapper, getValueProps } from '@refinedev/strapi-v4';
import { API_URL, TOKEN_KEY, URL } from '../../utils/constants';
import { Table, Input, Form, Drawer, Space, Image, Upload, Button } from 'antd';
import { useGo, useParsed } from '@refinedev/core';
import { Map, MapOutlined } from '@mui/icons-material';
import { AppContext } from '../../AppContext';
import { useContext } from 'react';
import { PinterestOutlined } from '@ant-design/icons';

export const StationList = () => {
  const go = useGo();
  const { store, actions } = useContext(AppContext);
  const { params } = useParsed();
  const [form] = Form.useForm();
  const { tableProps } = useTable({
    permanentFilter: [
      {
        field: 'organization][id]',
        operator: 'eq',
        value: params?.tenant
      }
    ],
    metaData: { populate: ['picture'] }
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    show: editShow,
    saveButtonProps,
    id,
    setId
  } = useDrawerForm({
    action: 'edit',
    redirect: false,
    queryOptions: {
      select: ({ data }) => {
        return {
          data: {
            ...data
          }
        };
      }
    }
  });

  const {
    formProps: formCreateProps,
    drawerProps: drawerCreateProps,
    show: showCreate
  } = useDrawerForm({
    action: 'create',
    warnWhenUnsavedChanges: true
  });

  return (
    <List
      canCreate
      createButtonProps={{
        onClick: () => {
          showCreate();
        }
      }}>
      <Drawer {...drawerCreateProps}>
        <Create
          footerButtons={({ defaultButtons }) => <></>}
          headerProps={{
            breadcrumb: undefined,
            backIcon: false,
            title: 'New Station'
          }}>
          <Form
            form={form}
            name="register"
            onFinish={(values) => {
              console.log(values);
            }}
            scrollToFirstError>
            <Form.Item
              name="imei"
              label="IMEI"
              rules={[
                {
                  required: true,
                  message: 'Please input your IMEI'
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Please input your address'
                }
              ]}>
              <Input />
            </Form.Item>

            <Form.Item label="Image">
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, API_URL)}
                noStyle
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Upload.Dragger
                  name="files"
                  action={`${API_URL}/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
                  }}
                  listType="picture"
                  multiple>
                  <p className="ant-upload-text">Drag & drop a file in this area</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>
          </Form>
        </Create>
      </Drawer>

      <Drawer {...editDrawerProps}>
        <Edit
          saveButtonProps={saveButtonProps}
          recordItemId={id}
          breadcrumb={null}
          headerButtons={({ defaultButtons }) => <RefreshButton />}>
          <Form
            {...editFormProps}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={(values) => {
              editFormProps.onFinish?.(mediaUploadMapper(values));
            }}>
            <Form.Item
              label="IMEI"
              name="imei"
              rules={[
                {
                  required: true
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item label="Image">
              <Form.Item
                name="picture"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, API_URL)}
                noStyle
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Upload.Dragger
                  name="files"
                  action={`${API_URL}/api/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
                  }}
                  listType="picture"
                  multiple>
                  <p className="ant-upload-text">Drag & drop a file in this area</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          </Form>
        </Edit>
      </Drawer>
      <Table
        {...tableProps}
        rowKey="id"
        size="small"
        pagination={{
          ...tableProps.pagination,
          pageSizeOptions: ['12', '24', '48', '96'],
          showTotal: (total) => `Total ${total} items`
        }}>
        <Table.Column
          dataIndex="id"
          title="Actions"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" onClick={() => editShow(record.id)} />
              </Space>
            );
          }}
        />
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex="picture"
          title="Picture"
          render={(e, value) => {
            console.log(value);
            return <Image width="50px" src={URL + value.picture['url']} alt="profile" />;
          }}
        />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="imei" title="Imei" />
        <Table.Column dataIndex="address" title="Address" />
        <Table.Column
          title="Location"
          render={(e, record) => {
            return (
              <Button
                icon={<Map />}
                onClick={() => {
                  console.log(record);
                  actions.setActiveTab('station', store);
                  actions.setSelectedDevice(record, store);
                  go({
                    to: `/${params.tenant}/map`
                  });
                }}></Button>
            );
          }}
        />
      </Table>
    </List>
  );
};
