
import { AuthHelper } from "@refinedev/strapi-v4";
import { TOKEN_KEY, API_URL } from "./utils/constants";
import axios from "axios";
const strapiAuthHelper = AuthHelper(API_URL);
const axiosInstance = axios.create();

export const authProvider = {
    login: async ({ email, password }) => {
        const { data, status } = await strapiAuthHelper.login(
            email,
            password,
        );
        if (status === 200) {
            localStorage.setItem(TOKEN_KEY, data.jwt);

            // set header axios instance
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${data.jwt}`;

            return {
                success: true,
                redirectTo: "/1/dashboard",
            };
        }
        return {
            success: false,
            error: {
                message: "Login failed",
                name: "Invalid email or password",
            },
        };
    },
    logout: async () => {
        localStorage.removeItem(TOKEN_KEY);
        return {
            success: true,
            redirectTo: "/login",
        };
    },
    onError: async (error) => {
        if (error.response?.status === 401) {
            return {
                logout: true,
            };
        }

        return { error };
    },
    check: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${token}`;
            return {
                authenticated: true,
            };
        }

        return {
            authenticated: false,
            error: {
                message: "Check failed",
                name: "Token not found",
            },
            logout: true,
            redirectTo: "/login",
        };
    },
    getPermissions: async () => null,
    getIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return null;
        }

        const { data, status } = await strapiAuthHelper.me(token, { meta: { populate: "*" } });
        if (status === 200) {
            const { id, username, email, rbac } = data;
            return {
                id,
                username,
                email,
                role: rbac.name
            };
        }

        return null;
    },
};