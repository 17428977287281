import { useContext, useEffect, useState } from 'react';
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import { AppContext } from '../../../AppContext';

import { motion, useAnimation } from 'framer-motion';

export default function Device({ children }) {
  const { store } = useContext(AppContext);
  const [active, setActive] = useState(false);
  const controls = useAnimation();

  const showMore = () => {
    controls.start({
      width: '230px',
      transition: { duration: 0.001 }
    });

    setActive(true);
    window.setTimeout(() => store.map && store.map.resize(), 500);
  };

  const showLess = () => {
    controls.start({
      width: '0px',
      marginRight: '0px',
      transition: { duration: 0.001 }
    });

    setActive(false);
    window.setTimeout(() => store.map && store.map.resize(), 500);
  };

  useEffect(() => {
    showMore();
  }, [store.activeMenu]);

  useEffect(() => {
    showMore();
  }, []);

  useEffect(() => {
    window.setTimeout(() => store.map && store.map.resize(), 500);
  }, [store.devicePosotions, store.stationPositions]);

  return (
    <div className="flex w-auto">
      <div className="flex w-auto">
        <motion.div
          animate={controls}
          className="max-w-[250px] animate duration-300 relative flex flex-col group">
          {active && (
            <BsFillArrowRightSquareFill
              onClick={showLess}
              className="absolute hidden text-2xl text-gray-500 z-10 cursor-pointer -left-4 top-10 group-hover:block"
            />
          )}
          {!active && (
            <BsFillArrowLeftSquareFill
              onClick={showMore}
              className="absolute text-2xl text-gray-500 z-10 cursor-pointer -left-4 top-10"
            />
          )}

          <div className="flex flex-col justify-between">{children}</div>
        </motion.div>
      </div>
    </div>
  );
}
