import {
  List,
  useDrawerForm,
  useTable,
  EditButton,
  Edit,
  Create,
  RefreshButton
} from '@refinedev/antd';
import { mediaUploadMapper, getValueProps } from '@refinedev/strapi-v4';
import { useParsed } from '@refinedev/core';
import { useSelect } from '@refinedev/antd';
import { Table, Input, Form, Drawer, Space, Upload, Button, Select, Image } from 'antd';
import { API_URL, TOKEN_KEY, URL } from '../../utils/constants';

export const DeviceList = () => {
  const { params } = useParsed();
  const [form] = Form.useForm();
  const { tableProps } = useTable({
    permanentFilter: [
      {
        field: 'organization][id]',
        operator: 'eq',
        value: params?.tenant
      }
    ],
    meta: {
      populate: ['organization', 'bike_user', 'picture']
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc'
        }
      ]
    }
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    show: editShow,
    saveButtonProps,
    id
  } = useDrawerForm({
    action: 'edit',
    redirect: false,
    meta: {
      populate: ['organization', 'bike_user', 'picture']
    },
    queryOptions: {
      select: ({ data }) => {
        console.log(data);
        return {
          data: {
            ...data,
            bike_user: data.bike_user.id
          }
        };
      }
    }
  });

  const {
    formProps: formCreateProps,
    drawerProps: drawerCreateProps,
    show: showCreate
  } = useDrawerForm({
    action: 'create',
    warnWhenUnsavedChanges: true
  });

  const { selectProps } = useSelect({
    resource: 'bike-users',
    optionLabel: 'name',
    optionValue: 'id'
  });

  return (
    <List
      canCreate
      createButtonProps={{
        onClick: () => {
          showCreate();
        }
      }}>
      <Drawer {...drawerCreateProps}>
        <Create
          footerButtons={({ defaultButtons }) => <></>}
          headerProps={{
            breadcrumb: undefined,
            backIcon: false,
            title: 'New Bike'
          }}>
          <Form
            form={form}
            name="register"
            onFinish={(values) => {
              formCreateProps.onFinish?.({
                ...mediaUploadMapper(values),
                organization: params?.tenant
              });
            }}
            scrollToFirstError>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please input your name'
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="model"
              label="model"
              rules={[
                {
                  required: true,
                  message: 'Please input your model'
                }
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name="vehicle_id"
              label="Vehicle No."
              rules={[
                {
                  required: true,
                  message: 'Please input your vn'
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="bike_user"
              label="Owner"
              rules={[
                {
                  required: true,
                  message: 'Please input your model'
                }
              ]}>
              <Select placeholder="Select a owner" style={{ width: 300 }} {...selectProps} />
            </Form.Item>

            <Form.Item label="Image">
              <Form.Item
                name="picture"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, URL)}
                noStyle
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Upload.Dragger
                  name="files"
                  action={`${API_URL}/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
                  }}
                  listType="picture"
                  multiple>
                  <p className="ant-upload-text">Drag & drop a file in this area</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>
          </Form>
        </Create>
      </Drawer>

      <Drawer {...editDrawerProps}>
        <Edit
          saveButtonProps={saveButtonProps}
          recordItemId={id}
          breadcrumb={null}
          headerButtons={({ defaultButtons }) => <RefreshButton />}>
          <Form
            {...editFormProps}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={(values) => {
              editFormProps.onFinish?.(mediaUploadMapper(values));
            }}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Vehicle No."
              name="vehicle_id"
              rules={[
                {
                  required: true
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Model"
              name="model"
              rules={[
                {
                  required: true
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="bike_user"
              label="Owner"
              rules={[
                {
                  required: true,
                  message: 'Please input your model'
                }
              ]}>
              <Select placeholder="Select a owner" {...selectProps} />
            </Form.Item>
            <Form.Item label="Image">
              <Form.Item
                name="picture"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, URL)}
                noStyle
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Upload.Dragger
                  name="files"
                  action={`${API_URL}/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
                  }}
                  listType="picture"
                  multiple>
                  <p className="ant-upload-text">Drag & drop a file in this area</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          </Form>
        </Edit>
      </Drawer>
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          pageSizeOptions: ['12', '24', '48', '96'],
          showTotal: (total) => `Total ${total} items`
        }}>
        <Table.Column
          dataIndex="id"
          title="Actions"
          width={100}
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" onClick={() => editShow(record.id)} />
              </Space>
            );
          }}
        />
        <Table.Column
          dataIndex="picture"
          title="Picture"
          width={150}
          render={(_, record) => {
            console.log(record?.picture && record.picture);
            return record?.picture?.map((each) => {
              return <Image src={URL + each.url} alt="picture" width={100} />;
            });
          }}
        />
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="model" title="Model" />
        <Table.Column
          dataIndex="bike_user"
          title="Owner"
          render={(_, record) => {
            return _?.name;
          }}
        />
      </Table>
    </List>
  );
};
