import React, { useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import { CountUp } from 'use-count-up';
import _ from 'lodash';
import { BsFillInfoCircleFill } from 'react-icons/bs';

const CarbonCard = () => {
  const { store } = React.useContext(AppContext);
  const [carbonData, setCarbonData] = React.useState();

  const calculateCabon = () => {
    const totalDistance = _.sumBy(store.devicePositions.data, (item) => {
      return parseFloat(item.attributes.sensor.mileage);
    });
    setCarbonData({
      ...carbonData,
      dist: parseFloat(totalDistance.toFixed(2)),
      co2: parseFloat((totalDistance * 0.11337).toFixed(2)),
      tree: parseFloat((totalDistance * 0.11337 * 0.041).toFixed(2))
    });
  };

  // generate random number
  useEffect(() => {
    if (store.devicePositions) {
      calculateCabon();
    }
  }, [store.devicePositions]);

  // console.log(carbonData);

  if (!carbonData || carbonData.dist === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col w-full opacity-80 rounded-lg">
      <div className="flex items-center bg-white p-2 rounded-md space-x-2 rounded-l-full">
        <div className="flex-1/3">
          <img src={'../assets/motorbike.png'} className="w-10 h-10 p-1" />
        </div>
        <div className="flex flex-col justify-end">
          <div className="font-bold text-[28px] flex space-x-2 items-center">
            {' '}
            <CountUp
              isCounting
              end={carbonData.dist}
              duration={5}
              decimals={4}
              decimalSeparator="."
              thousandsSeparator=","
            />{' '}
            <p className="flex text-[28px] font ml-3"> Km</p>
          </div>
          <div className="flex text-[16px]">Total Distance Driven</div>
        </div>
      </div>

      <div className="flex items-center space-x-2 bg-green-600 text-white p-2 rounded-md rounded-l-full">
        <div className="flex-1/2">
          <img src={'../assets/co2.png'} className="w-10 h-10 p-1" />
        </div>
        <div className="flex flex-col justify-end">
          <div className="flex stat-value text-[28px]">
            <CountUp
              isCounting
              end={carbonData.co2}
              duration={5}
              decimals={4}
              decimalSeparator="."
              thousandsSeparator=","
            />
            <p className="flex text-[28px] font ml-3"> Kg</p>
            <button
              onClick={() =>
                window.open(
                  'https://thrustcarbon.com/insights/how-to-calculate-motorbike-co2-emissions',
                  '_blank'
                )
              }>
              <BsFillInfoCircleFill className="ml-2 w-3 h-3 text-blue-200" />
            </button>
          </div>
          <div className="flex text-[16px]">Total CO2 Emission Saved</div>
        </div>
      </div>

      <div className="flex items-center space-x-2 bg-green-500 text-white p-2 rounded-md rounded-l-full">
        <div className="flex-1/3">
          <img src={'../assets/landscape.png'} className="w-10 h-10 p-1" />
        </div>
        <div className="flex flex-col justify-end">
          <div className="flex stat-value text-[28px] ">
            <CountUp
              isCounting
              end={carbonData.tree}
              duration={5}
              decimals={4}
              decimalSeparator="."
              thousandsSeparator=","
            />
            <p className="flex text-[28px] font ml-3"> Trees</p>
            <button
              onClick={() =>
                window.open(
                  'https://www.viessmann.co.uk/en/heating-advice/boilers/how-much-co2-does-tree-absorb.html',
                  '_blank'
                )
              }>
              <BsFillInfoCircleFill className="ml-2 w-3 h-3 text-blue-200" />
            </button>
          </div>
          <div className="flex text-[16px]"> Equivalent Total Tree Planted</div>
        </div>
      </div>
    </div>
  );
};

export default CarbonCard;
