import { getColor } from '../../../utils';

function BatteryStatus({ percent }) {
  return (
    <>
      <div className="m-auto flex h-20 w-10 flex-col justify-center rounded-lg border-2">
        <div className="mx-auto -mt-2 h-2 w-5 rounded-t-lg bg-gray-400"></div>
        <div className="m-[2px] flex h-full w-auto rounded-md ">
          <div
            className={`self-end flex rounded-md ${getColor(
              percent
            )} w-full justify-center items-center`}
            style={{ height: `${percent}%` }}>
            <span className="text-white text-sm">{percent}%</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default BatteryStatus;
