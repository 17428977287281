const mapIcon = {
  name: 'default-sensor.svg',
  ordometer: 'odometer.svg',
  gps: 'default-sensor.svg',
  gsm: 'gsm.png',
  speed: 'speed.svg',
  update: 'default-sensor.svg',
  distance: 'default-sensor.svg',
  ignition: 'default-sensor.svg',
  location: 'address.svg',
  battery: 'battery.svg',
  angle: 'default-sensor.svg',
  altitude: 'default-sensor.svg',
  latitude: 'default-sensor.svg',
  mileage: 'default-sensor.svg',
  satellite: 'satellite.png',
  longitude: 'default-sensor.svg',
  deviceTime: 'default-sensor.svg',
  status: 'default-sensor.svg',
  // battery icon
  id: 'default-sensor.svg',
  soc: 'battery-status.png',
  imei: 'default-sensor.svg',
  slot: 'default-sensor.svg',
  temp: 'temp.png',
  cycle: 'recycle.png',
  // station icon
  temperature: 'temp.png',
  voltage: 'charging-station.png'
};

export default mapIcon;
