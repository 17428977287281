import { Layout as AntdLayout, Grid } from "antd";
import { useLocation } from "react-router-dom";

export const Layout = ({
    children,
    Header,
    Footer,
    Sider,
    OffLayoutArea,
}) => {
    const breakpoint = Grid.useBreakpoint();
    const location = useLocation();
    console.log(location.pathname.includes("/map"));
    return (
        <AntdLayout style={{ minHeight: "100vh", flexDirection: "row" }}>
            {Sider && <Sider />}

            <AntdLayout >
                {Header && <Header />}
                <AntdLayout.Content>
                    <div
                        style={{
                            padding:
                                !(location.pathname.includes("/map") ||
                                    location.pathname.includes("/dashboard") ||
                                    location.pathname.includes("/notification") ||
                                    location.pathname.includes("/simulation"))
                                    ? breakpoint.sm
                                        ? 24
                                        : 12
                                    : 0,
                            minHeight: 360
                        }}
                    >
                        {children}
                    </div>
                </AntdLayout.Content>
                {Footer && <Footer />}
            </AntdLayout>
            {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout>
    );
};