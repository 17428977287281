import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import { AppContext } from '../../../AppContext';
import { DeviceList } from './deviceList';

import { motion, useAnimation } from 'framer-motion';
import { getDevicePositions, getStationPositions, useInterval } from '../../../utils';

export default function Device({ socket }) {
  const [firstRender, setFirstRender] = useState(true);
  const { store, actions } = useContext(AppContext);
  const [active, setActive] = useState(false);
  const controls = useAnimation();

  const showMore = () => {
    controls.start({
      width: '300px',
      transition: { duration: 0.001 }
    });
    window.setTimeout(() => store.map && store.map.resize(), 500);
    setActive(true);
  };

  const showLess = () => {
    controls.start({
      width: '0px',
      transition: { duration: 0.001 }
    });
    window.setTimeout(() => store.map && store.map.resize(), 500);
    setActive(false);
  };

  const getDeviceRealtime = async () => {
    const allDevices = await getDevicePositions();
    const allStations = await getStationPositions();
    if (store.selectedDevice) {
      // update selected device data
      const newDeviceData = _.filter(
        [...store.devicePositions.data, ...store.stationPositions.data],
        (each) => each.attributes.imei === store.selectedDevice.imei
      );

      // console.log("load devices", newDeviceData, store.selectedDevice);
      return actions.setPositions([allDevices, allStations, newDeviceData[0].attributes], store);
    }
    // REOPEN LATER
    if (firstRender) {
      setFirstRender(false);
      // console.log('load devices', allDevices, allStations, allDevices.data[0].attributes);
      return actions.setPositions([allDevices, allStations, allDevices.data[0].attributes], store);
    }

    return actions.setPositions([allDevices, allStations, null], store);
  };

  const handleFilter = (e) => {
    if (e) {
      const newFilterDevices = _.filter(store.devicePositions.data, (each) =>
        each.attributes.name.includes(e)
      );
      actions.setFilterDevices({ ...store.devicePositions, data: newFilterDevices }, e, store);
    } else {
      actions.setFilterDevices([], e, store);
    }
  };
  // console.log(store.filterDevices, store.searchTerm);

  useEffect(() => {
    showMore();
  }, []);

  const intervalRef = useInterval(() => {
    getDeviceRealtime();
    // console.log("second run");
  }, 5000);

  // get data first run
  useEffect(() => {
    // first run
    return () => clearInterval(intervalRef);
  }, []);

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-auto border shadow-lg ">
        <motion.div
          animate={controls}
          className="max-w-[300px]  animate duration-300 relative flex flex-col min-h-map group">
          {active && (
            <BsFillArrowLeftSquareFill
              onClick={showLess}
              className="absolute hidden text-2xl text-gray-500 z-20 cursor-pointer -right-4 bottom-10 group-hover:block "
            />
          )}
          {!active && (
            <BsFillArrowRightSquareFill
              onClick={showMore}
              className="absolute text-2xl text-gray-500 z-20 cursor-pointer -right-4 bottom-10"
            />
          )}
          <div className="flex flex-col h-map justify-between">
            <div className="flex flex-col space-y-2">
              <DeviceList />
            </div>
            <div>
              <div className="m-1">
                <input
                  type="text"
                  placeholder="Search Bikes"
                  onChange={(e) => handleFilter(e.target.value)}
                  className="input input-bordered h-8 input-primary w-full max-w-xs"
                />
              </div>
              <div className="bg-yellow-500 m-2 rounded-md shadow-lg justify-center">
                <img
                  src="../assets/logo.png"
                  alt="logo"
                  width={140}
                  height="100%"
                  className="py-1 mx-auto"
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
