import { StoreSelect } from "../utils/select-tenant";

function Navbar() {
  return (
    <div className="navbar bg-gradient-to-r from-white to-[#ffe511] h-auto shadow-lg max-h-10">
      <div className="flex-1 space-x-3">
        {/* <a className="btn btn-ghost normal-case text-2xl text-white">
          EV Management Platform
        </a> */}
        <div className="w-200 rounded-full mr-16">
          <img src="../assets/logo.png" width={150} alt="logo" />
        </div>
      </div>
      <StoreSelect />
      <div className="flex-none ml-5">
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-12 rounded-full border border-gray-300 thumbnail">
              <img src="../assets/profile.png" alt="logo" />
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
