import { Reorder } from 'framer-motion';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import { getProfiles, serverUrl } from '../../../utils';

function DriverRanking() {
  const { store } = React.useContext(AppContext);
  const [users, setUsers] = React.useState([]);
  const [driverData, setDriverData] = React.useState();

  const getDrivers = async () => {
    const drivers = await getProfiles();
    const formatDriver = _.map(drivers.data, (item) => item.attributes);
    setUsers(formatDriver);
  };
  const updateRanking = () => {
    const newData = { ...store.devicePositions.data };
    const sortedDriver = _.orderBy(
      newData,
      (item) => parseFloat(item.attributes.sensor.mileage),
      'desc'
    );
    // limit only at most 5 drivers
    const topDriver = sortedDriver.slice(0, 3);
    const addProfile = _.map(topDriver, (each) => {
      const getProfile = _.filter(users, (item) => item.imei === each.attributes.imei);
      return {
        ...each.attributes,
        bike: each.attributes.name,
        distance: each.attributes.sensor.mileage,
        ...getProfile[0]
      };
    });
    // remove no driver
    // let haveDriver = _.filter(addProfile, (item) => item.profile);
    // console.log("top driver", topDriver, sortedDriver, addProfile, haveDriver);
    setDriverData([...addProfile]);
  };

  useEffect(() => {
    // console.log("do ranking");
    updateRanking();
  }, [store.devicePositions])

  useEffect(() => {
    // console.log("get driver");
    getDrivers();
  }, []);

  // console.log("top driver", driverData);
  if (!driverData) {
    return <></>;
  }

  return (
    <>
      <div className="overflow-x-auto w-full mb-2 opacity-80">
        <Reorder.Group values={driverData} onReorder={setDriverData}>
          <table className="table table-compact w-full">
            <tbody>
              {driverData.map((each, index) => (
                <Reorder.Item as="tr" key={each.imei} value={each.distance}>
                  <td className="rounded-l-full bg-white" key={each.imei}>
                    <div className="flex items-center space-x-2">
                      {each.profile && (
                        <div className="avatar placeholder">
                          <div className="ml-1 w-10 h-10 rounded-full  border border-gray-400 thumbnail">
                            <img src={serverUrl + each.profile.data[0].attributes.url} />
                          </div>
                        </div>
                      )}
                      {!each.profile && (
                        <div className="avatar online placeholder">
                          <div className="ml-1 w-8 h-8 rounded-full">
                            <img src={'../assets/motorcycle.png'} width={16} />
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col">
                        <div className="flex font-bold text-lg justify-center items-center">
                          <span className="rounded-full border-2 border-gray-400 bg-green-400 text-gray-700 flex justify-center items-center mx-1 w-5 h-5">
                            {index + 1}
                          </span>
                          {each.name} [{each.bike}]
                        </div>
                        <div className="flex">
                          <div className="badge badge-success badge-sm">
                            {Math.floor(each.distance / 80)} Points
                          </div>
                          <div className="badge badge-accent badge-sm">{each.distance} Km</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </Reorder.Item>
              ))}
            </tbody>
          </table>
        </Reorder.Group>
      </div>
    </>
  );
}

export default DriverRanking;
