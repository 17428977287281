import mapIcon from './mapIcon';
import BatteryStatus from './verticalBar';

const batteryData = ({ data }) => {
  console.log(data);

  delete data.business_hours;
  // loop thru data if and split every 4 items
  if (!data.battery) {
    return (
      <div>
        <div className="flex flex-col w-48 pt-3 h-full bg-white border rounded-md justify-center items-center">
          <img src={'../assets/no-data-found.png'} className="object-scale-down h-28" />
        </div>
        <div className="absolute m-">
          <div className="badge badge-secondary badge-outline">Battery Data</div>
        </div>
      </div>
    );
  }
  return (
    <div className="space-y-1 flex-col">
      <div className="flex mb-1 justify-start items-start">
        <div className="badge badge-secondary badge-outline">Battery Data</div>
      </div>
      <div className="flex overflow-x-scroll no-scrollbar h-32 bg-white pt-2 rounded-lg">
        {data.battery.map((each, index) => {
          return (
            <div className="flex" key={index}>
              <div className="flex justify-center w-12 pt-1 h-auto bg-white">
                <BatteryStatus percent={each.soc} />
              </div>
              <ul className="menu menu-compact bg-base-100 w-32 px-1 py-1 justify-start">
                {Object.keys(each).map((item, indx) => {
                  return (
                    <li key={indx}>
                      <div className="flex justify-between py-[2px]">
                        <div className="flex space-x-2">
                          <div className="flex text-sm">
                            <img src={`../assets/svg/${mapIcon[item]}`} alt="icons" width={18} />
                          </div>
                          <div className="flex text-sm justify-start">
                            <p className="text-start">{item}</p>
                          </div>
                        </div>
                        <div className="flex justify-end text-sm">
                          <span>{data.battery[index][item]}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default batteryData;
