import { List, useTable } from '@refinedev/antd';
import { Table, Image } from 'antd';
import { URL } from '../../utils/constants';

export const UserList = () => {
  const { tableProps } = useTable({
    metaData: { populate: ['profile'] },
    pagination: {
      pageSize: 8
    }
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id" size="small" scroll={{ y: 'calc(100vh - 300px)' }}>
        <Table.Column dataIndex="id" title="Id" />

        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="name"
          title="Avatar"
          render={(e, value) => {
            return <Image width="50px" src={URL + value.profile[0]['url']} alt="profile" />;
          }}
        />
        <Table.Column dataIndex="gender" title="Gender" />
        <Table.Column dataIndex="imei" title="Bike ID" />
      </Table>
    </List>
  );
};
