import React from 'react';
import Map from './map/map.js';
import '../../App.css';
import '@tremor/react/dist/esm/tremor.css';
import Device from './deviceList';
import DetailData from './detailData/index';
import CarbonCard from './overviewBoard';
import { ContextWrapper } from '../../AppContext.js';
import socketIO from 'socket.io-client';
import { serverUrl } from '../../utils/index.js';

const socket = socketIO.connect(serverUrl);

function MapLayout() {
  return (
    <div className="flex m-0 font-ntfont">
      <div className="flex w-full">
        <div className="flex h-map">
          <Device socket={socket} />
        </div>
        <div className="flex w-full">
          <div className="relative w-full">
            <Map />
            <div className="absolute top-2 right-0 w-auto">
              <CarbonCard />
            </div>
            <div className="absolute bottom-0 w-full bg-white z-10">
              <DetailData />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapLayout;
