import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import toast from 'react-hot-toast';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { VscDebugStart, VscOpenPreview } from 'react-icons/vsc';
import {
  getSimulations,
  randomIntFromInterval,
  runSimulation,
  sendData,
  stopSimulation
} from '../../utils';
import Map from './map';

const colors = {
  stop: '#ff0000',
  idle: '#8fce00',
  driving: '#008000',
  online: '#ff0000',
  offline: '#808080'
};

function SimulationScreen() {
  const [simulationData, setSimulationData] = React.useState([]);
  const [zoomRoute, setZoomRoute] = React.useState();
  const [playRoute, setPlayRoute] = React.useState();
  const [simulationState, setSimulationState] = React.useState([]);
  const [dataToSubmit, setDataToSubmit] = React.useState([]);

  // send data when driving and when no driving

  const getAllSimulations = async () => {
    const allData = await getSimulations();

    const filterOnlyRouteExist = _.filter(
      allData.data,
      (item) => item.attributes.route && item.attributes.position.data
    );

    // display all simulation device
    setSimulationData(filterOnlyRouteExist);
    const initSimulationState = new Array(filterOnlyRouteExist.length).fill(false);

    // set initital stage of driving
    setSimulationState(initSimulationState);

    // setLastDeviceCoordinates(initialCoordinates);
    // set data to submit

    const initialDataToSubmit = filterOnlyRouteExist.map((each) => {
      const data = each.attributes;

      return {
        deviceID: data.position.data.id,
        name: data.position.data.attributes.name,
        serverTime: moment().valueOf(),
        deviceTime: moment().valueOf(),
        hideRoute: false,
        color: colors.online,
        // status: "online",
        online: true,
        // ignition: false,
        imei: data.imei,
        // longitude: data.route.features[0].geometry.coordinates[0][0],
        // latitude: data.route.features[0].geometry.coordinates[0][1],
        altitude: randomIntFromInterval(-10, 10),
        angle: randomIntFromInterval(0, 359),
        speed: randomIntFromInterval(0, 50)
      };
    });
    setDataToSubmit([...initialDataToSubmit]);
  };

  const sendingData = async () => {
    const filterOnline = _.filter(dataToSubmit, (each) => each.online);
    if (filterOnline.length !== 0) {
      const sendAll = _.map(filterOnline, async (each) => {
        return await sendData({
          ...each,
          speed: randomIntFromInterval(40, 50),
          serverTime: moment().valueOf(),
          deviceTime: moment().valueOf()
          // sensor: { ...each.sensor, satellite: randomIntFromInterval(1, 4) },
        });
      });
      Promise.all(sendAll).then((res) => {
        console.log('data sent', res);
      });
    }
  };

  const handleOnline = (e) => {
    const newOnline = [...dataToSubmit];
    newOnline[e].online = !newOnline[e].online;
    newOnline[e].color = dataToSubmit[e].online ? colors.online : colors.offline;
    newOnline[e].status = newOnline[e].online ? 'online' : 'offline';
    setDataToSubmit([...newOnline]);
  };

  const handleIgnition = (e) => {
    const newIgnition = [...dataToSubmit];
    newIgnition[e].ignition = !newIgnition[e].ignition;
    newIgnition[e].sensor.ignition = newIgnition[e].ignition ? 1 : 0;
    newIgnition[e].color = dataToSubmit[e].ignition ? colors.idle : colors.stop;
    newIgnition[e].status = 'idle';
    newIgnition[e].speed = randomIntFromInterval(0, 50);
    setDataToSubmit([...newIgnition]);
  };

  const handleShowRoute = (e) => {
    const newHide = [...dataToSubmit];
    newHide[e].hideRoute = !newHide[e].hideRoute;
    setDataToSubmit([...newHide]);
  };

  const handleStopAnimate = (e) => {
    // console.log(e);
    const newState = [...simulationState];
    newState[e] = false;
    setSimulationState(newState);
  };

  const lastPosition = (position, id) => {
    // let previousCoordinates = [...lastDeviceCoordinates];
    // previousCoordinates[id] = position;
    // // console.log(position, id);
    // setLastDeviceCoordinates(previousCoordinates);
    // // update data to send
    // console.log("dataToSubmit position update", dataToSubmit, id);
    // let newDataSubmit = [...dataToSubmit];
    // newDataSubmit[id].latitude = position[1];
    // newDataSubmit[id].longitude = position[0];
    // newDataSubmit[id].color = colors.driving;
    // newDataSubmit[id].status = "driving";
    // newDataSubmit[id].speed = randomIntFromInterval(0, 50);
    // console.log("call last position", dataToSubmit);
    // setDataToSubmit([...newDataSubmit]);
  };

  const simulateBackground = async (each) => {
    const simulate = await runSimulation(each);
    if (simulate.status === 'ok') {
      return toast.success('Simulation start successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      });
    }
  };
  const startAllSimulateBackground = async () => {
    toast.success('Stop all previous simulation successfully', {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff'
      }
    });
    const eachPromise = _.map(simulationData, async (each) => await runSimulation(each));
    Promise.all(eachPromise).then((res) => {
      return toast.success('Simulation start all successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      });
    });
  };

  const stopSimulateBackground = async () => {
    const simulate = await stopSimulation();
    if (simulate.status === 'ok') {
      return toast.success('Simulation stop successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      });
    }
  };
  // send data to sever every 10 second
  React.useEffect(() => {
    getAllSimulations();
  }, []);

  React.useEffect(() => {
    // const interval = setInterval(() => {
    //   // sending data one by one
    //   // sendingData();
    //   console.log("every 5 seconds", dataToSubmit[0].latitude);
    // }, 5000);
    // return () => clearInterval(interval);

    if (dataToSubmit[0]) {
      // console.log("every 5 seconds", dataToSubmit[0].latitude);
      sendingData();
    }
  }, [dataToSubmit, sendingData]);

  if (!simulationData) {
    return <>Loading</>;
  }
  return (
    <div className="flex w-full">
      <div className="flex flex-col justify-between shadow-lg z-10 w-1/3 overflow-y-scroll h-screen">
        <div>
          <div className="h-auto flex p-2">
            <div className="justify-end flex w-full space-x-2">
              <button
                className="bg-gray-500 rounded-md px-2 py-1 shadow-md flex space-x-2 text-white items-center"
                onClick={() => {
                  stopSimulateBackground();
                }}>
                Stop All
              </button>
              <button
                className="bg-green-500 rounded-md px-2 py-1 shadow-md flex space-x-2 text-white items-center"
                onClick={() => {
                  startAllSimulateBackground();
                }}>
                Start All
              </button>
            </div>
          </div>
          <div className="overflow-x-hidden w-full flex">
            <table className="table table-zebra table-compact w-full">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Imei</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {simulationData.map((each, index) => (
                  <tr key={index}>
                    <th>{each.id}</th>
                    <td>{each.attributes.name}</td>
                    <td>{each.attributes.imei}</td>
                    <td>
                      <div className="flex space-x-1 flex-wrap">
                        <div className="tooltip" data-tip="driving">
                          <button
                            className="bg-green-500 rounded-md p-1 shadow-md"
                            onClick={() => {
                              // start simulation
                              simulateBackground(each);

                              const newState = [...simulationState];
                              newState[index] = !simulationState[index];
                              setSimulationState(newState);
                              setPlayRoute(
                                !simulationState[index] ? { ...each, orderID: index } : null
                              );
                            }}>
                            <VscDebugStart className="w-4 h-4 text-white" />
                          </button>
                        </div>
                        {/* <div className="tooltip" data-tip="simulate server">
                        <button
                          className="bg-gray-500 rounded-md p-1 shadow-md"
                          onClick={() => {
                            simulateBackground(each);
                          }}
                        >
                          <RiRestartFill className="w-4 h-4 text-white" />
                        </button>
                      </div> */}
                        <div className="tooltip" data-tip="online/offline">
                          <input
                            type="checkbox"
                            checked={dataToSubmit[index] ? dataToSubmit[index].online : true}
                            onClick={() => {
                              handleOnline(index);
                            }}
                            className="checkbox checkbox-success checkbox-md"
                          />
                        </div>
                        <div className="tooltip" data-tip="Ignition On/Off">
                          <input
                            type="checkbox"
                            checked={dataToSubmit[index] ? dataToSubmit[index].ignition : false}
                            onClick={() => {
                              handleIgnition(index);
                            }}
                            className="checkbox checkbox-warning checkbox-md"
                          />
                        </div>
                        <div className="tooltip" data-tip="zoom to">
                          <button
                            className="bg-gray-500 rounded-md p-1 shadow-md"
                            onClick={() => {
                              setZoomRoute(each);
                            }}>
                            <VscOpenPreview className="w-4 h-4 text-white" />
                          </button>
                        </div>
                        <div className="tooltip" data-tip="show/hide route">
                          <button
                            className="bg-gray-500 rounded-md p-1 shadow-md"
                            onClick={() => {
                              // show and off path
                              handleShowRoute(index);
                            }}>
                            {dataToSubmit[index] && !dataToSubmit[index].hideRoute ? (
                              <BsEye className="w-4 h-4 text-white" />
                            ) : (
                              <BsEyeSlash className="w-4 h-4 text-white" />
                            )}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="w-auto min-h-64 border rounded-md m-3 p-3 text-wrap overflow-y-scroll">
          <pre className="break-all text-sm">
            {JSON.stringify(dataToSubmit, null, 4)}
          </pre>
        </div> */}
      </div>
      <div className="flex w-2/3">
        <Map
          data={simulationData}
          zoomTo={zoomRoute}
          playRoute={playRoute}
          handleStopAnimate={handleStopAnimate}
          lastPosition={lastPosition}
          dataToSubmit={dataToSubmit}
          simulationState={simulationState}
        />
      </div>
    </div>
  );
}

export default SimulationScreen;
