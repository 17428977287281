import moment from 'moment';
import React from 'react';
import { serverUrl } from '../../../utils';
import mapIcon from './mapIcon';
const stationData = ({ data }) => {
  // loop thru data if and split every 4 items
  // take sensor out
  const dataFlate = { ...data, ...data.sensor };
  dataFlate.update = dataFlate.deviceTime;
  const image = dataFlate.picture?.data?.attributes.url || '';
  delete dataFlate.sensor;
  delete dataFlate.createdAt;
  delete dataFlate.updatedAt;
  delete dataFlate.serverTime;
  delete dataFlate.publishedAt;
  delete dataFlate.altitude;
  delete dataFlate.deviceTime;
  delete dataFlate.battery;
  delete dataFlate.angle;
  delete dataFlate.longitude;
  delete dataFlate.latitude;
  delete dataFlate.picture;
  delete dataFlate.business_hours;
  delete dataFlate.organization;

  // foramting data
  dataFlate.update = moment().format('YY-MM-DD HH:mm:ss'); // TO DO change to current time every 5 second
  // dataFlate.latitude = parseFloat(dataFlate.latitude).toFixed(5);
  // dataFlate.longitude = parseFloat(dataFlate.longitude).toFixed(5);
  // dataFlate.ignition = dataFlate.ignition ? "On" : "Off";
  dataFlate.speed = dataFlate.speed + ' Km/h';
  dataFlate.voltage = dataFlate.voltage + ' v';

  console.log('dataFlate', dataFlate);

  return (
    <div className="">
      <div className="flex mb-1">
        <div className="badge badge-secondary badge-outline">Station Data</div>
      </div>
      <div className="flex h-32 no-scrollbar">
        <div className="flex flex-col justify-center bg-white">
          {image && (
            <img
              src={serverUrl + image}
              className="my-auto shadow-md m-2 rounded-md"
              alt="station"
              width={140}
            />
          )}
          {!image && (
            <img
              src={'../assets/140.png'}
              className="my-auto m-2 rounded-md"
              alt="station"
              width={120}
            />
          )}
        </div>
        {getChunk(Object.keys(dataFlate)).map((each, index) => {
          return (
            <>
              <ul className="menu menu-compact bg-base-100 w-60 p-2 justify-start">
                {each.map((item) => {
                  console.log(item);
                  return (
                    <li key={index}>
                      <div className="flex justify-between py-[1px]">
                        <div className="flex space-x-2">
                          <div className="flex text-sm">
                            {mapIcon[item] && (
                              <img src={`../assets/svg/${mapIcon[item]}`} alt="icons" width={18} />
                            )}
                            {!mapIcon[item] && (
                              <img src={'../assets/svg/default-sensor.svg'} alt="icons" width={18} />
                            )}
                          </div>
                          <div className="flex text-sm justify-start">
                            <p className="text-start">{item}</p>
                          </div>
                        </div>
                        <div className="flex justify-end text-sm">
                          <span className="overflow-hidden truncate">{dataFlate[item]}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          );
        })}
      </div>
    </div>
  );
};

const getChunk = (array) => {
  const chunkSize = 5;
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    // do whatever
    result[i] = chunk;
  }
  return result;
};

export default stationData;
