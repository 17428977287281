import React, { useState } from 'react';

export const AppContext = React.createContext(null);

export const ContextWrapper = (props) => {
  const [store, setStore] = useState({
    devicePositions: [],
    stationPositions: [],
    selectedDevice: null,
    map: null,
    activeMenu: 'Home',
    activeTab: 'bike',
    filterDevices: [],
    searchTerm: ''
  });
  const [actions] = useState({
    setMap: (map, store) => setStore({ ...store, map }),
    setActiveMenu: (menu, store) => setStore({ ...store, activeMenu: menu }),
    setActiveTab: (tab, store) => setStore({ ...store, activeTab: tab }),
    setSelectedDevice: (device, store) => setStore({ ...store, selectedDevice: device }),
    setFilterDevices: (devices, searchTerm, store) =>
      setStore({ ...store, filterDevices: devices, searchTerm }),
    setPositions: ([devices, stations, device], store) =>
      setStore({
        ...store,
        devicePositions: devices,
        stationPositions: stations,
        selectedDevice: device
      })
  });

  return <AppContext.Provider value={{ store, actions }}>{props.children}</AppContext.Provider>;
};
